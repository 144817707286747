<template>
  <power-bi-report :report-id="reportId" />
</template>

<script>
export default {
  components: {
    PowerBiReport: () => import('@/modules/external_bi/components/PowerBiReport')
  },
  computed: {
    reportId() {
      return 'ad5eb821-4196-4ab8-90a9-34ae8c55eb49'
    }
  }
}
</script>
